.container_followsection {
  height: 90vh;

  width: fit-content;
  overflow-y: hidden;
  overflow-x: hidden;
}
.searchbar {
  margin-top: 2vh;
}
.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
  margin-left: 10vw;
  z-index: 100;
}
@media screen and (max-width: 1000px) {
  .sidebar {
    margin-top: 5vh;
  }
}
@media screen and (max-width: 700px) {
}
@media screen and (max-height: 1000px) {
  .sidebar {
    margin-top: 5vh;
  }
}

.bySize {
  z-index: 100;
  margin-top: 2vh;
  color: #fff;
  background: none;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 10px;
  width: 50px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.5s;
  text-transform: uppercase;
  border: 1px solid #0ba9ca;
  text-align: center;
}

.bySize:hover {
  box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 20px #0ba9ca, 0 0 30px #0ba9ca,
    0 0 40px #0ba9ca, 0 0 50px #0ba9ca;
  background: #0ba9ca;
}

.container_heading {
  width: fit-content;
  position: fixed;
  left: 40vw;
  padding: 2rem 6rem;
  z-index: 50;
}
.container_heading h1 {
  color: white;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
  text-align: left;
}
.container_heading h2 {
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 32px;
  margin: 0 0 72px;
  text-align: left;
}
