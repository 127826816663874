.news_container {
  margin-top: 10vh;
}
.title_news {
  color: white;
  text-align: center;
  font-size: 5vw;
  font-weight: 800;
  margin-bottom: 15vh;
}
.container_singlenews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}
.eintrag_news {
  padding: 5vh 4vw;
  word-wrap: break-word;
  hyphens: auto;
}
.container_news {
  max-width: 600px;
  height: 400px;
  margin: 0 2vw;
  margin-bottom: 7vh;
}
.container_news h4 {
  margin: 0.5vh 0vw;
}
.container_news h2 {
  margin: 0.5vh 0vw;
  line-height: 1.2;
  overflow-wrap: break-word;
}
.figure_news {
  font-family: var(--font-family);
  position: relative;
  overflow: hidden;
  height: 400px;
  width: 100%;
  color: #ffffff;
  text-align: left;
  display: flex;
  align-items: center;
  line-height: 1.4em;
  background-color: #141414;
  border-radius: 25px;
  padding-bottom: 5vh;
  box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 20px #0ba9ca, 0 0 30px #0ba9ca,
    0 0 40px #0ba9ca, 0 0 50px #0ba9ca;
  -webkit-animation: blink 2s infinite alternate;
  animation: blink 2s infinite alternate;
}

@keyframes blink {
  100% {
    box-shadow: 0 0 3px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #0ba9ca,
      0 0 70px #0ba9ca, 0 0 80px #0ba9ca;
  }
}
.figure_news * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.figure_news .background {
  width: 100%;
  vertical-align: text-top;
  opacity: 0.6;

  -webkit-filter: grayscale(100%) blur(1.5px);
  filter: grayscale(100%) blur(1.5px);
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
  transform: scale(1.5);
  -ms-transform: scale(1.5); /* IE 9 */
  -moz-transform: scale(1.5); /* Firefox */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5);
}
.row_news {
  position: absolute;
  z-index: 1000;
}
