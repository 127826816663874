.search-bar-dropdown {
  display: flex;
  flex-direction: column;
  font-size: 1vh;
  max-height: 24vh;
  height: auto;
  border-radius: 5px;

  border: 0.1px solid #0ba9ca;
  background-color: black;
  transition: 1s;
  width: fit-content;
}

.search-res {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 21vh;
  overflow: auto;
  transition: 1s;
  border-radius: 5px;
  width: 100%;
}

.search-res button {
  padding-left: 1vw;

  color: #fff;
  background: none;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  height: 3vh;
  outline: none;
  cursor: pointer;
  line-height: 25px;
  transition: 0.5s;
  text-transform: uppercase;
  border: 1px solid #0ba9ca;
  text-align: left;
}
.search-res button:hover {
  box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 20px #0ba9ca, 0 0 30px #0ba9ca,
    0 0 40px #0ba9ca, 0 0 50px #0ba9ca;
  background: #0ba9ca;
}
.inputSearch {
  background: none;
  border: 1px solid #0ba9ca;
  height: 3vh;
  padding-left: 1vw;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 25px;
  color: white;
}
.inputSearch::placeholder {
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  font-size: 1.5vh;
}
.inputSearch:focus {
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 20px #0ba9ca, 0 0 30px #0ba9ca,
    0 0 40px #0ba9ca, 0 0 50px #0ba9ca;
  border: 1px solid #0ba9ca;
  outline: none !important;

  background: none;
}
@media screen and (max-width: 1100px) {
  .search-res button {
    font-size: 10px;
  }
}
