.container_infobox {
  position: fixed;
  margin-top: 10vh;
  right: 0;
  display: flex;
  flex-direction: column;
  color: white;

  z-index: 105;
  align-items: center;
}
.container_infotext {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid white;
  transition: 1s;
  font-size: 12px;
}
table.info_table tr:hover {
  box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 20px #0ba9ca, 0 0 30px #0ba9ca,
    0 0 40px #0ba9ca, 0 0 50px #0ba9ca;
  transition: 0.3s;
}
table.info_table td:nth-child(1) {
  border-right: 1px solid #eeeeee;
  color: #0ba9ca;
  font-weight: 900;
}

/** Score Table Color */

/** Background of Table */
table.info_table th,
table.info_table td {
  padding: 6px 20px 6px 20px;
  text-align: left;

  vertical-align: middle;
}

table.info_table thead {
  background: none;
  color: white;
  border: 1px solid #474747;
  text-transform: uppercase;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 1px;
}

table.info_table {
  border-spacing: 0;
  border-style: hidden;
  border-radius: 5px;
  border-collapse: collapse;
  border: 1px solid #474747;

  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  width: 50%;
}
.bySize_info {
  z-index: 100;
  color: #fff;
  background: none;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 10px;
  width: 50px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.5s;
  text-transform: uppercase;
  border: 1px solid #0ba9ca;
  text-align: center;
}
/** Rank Column */
table.info_table th:nth-child(1),
table.info_table td:nth-child(1) {
  width: 10px;
  border-right: 1px solid #b8b8b8;
}

table.info_table th,
table.info_table td {
  border-right: 1px solid #b8b8b8;
}

table.info_table td:nth-last-child(1) {
  border-right: none;
  letter-spacing: 0.5px;
  font-family: droid serif;
}

td {
  color: white;
}

::-moz-selection {
  text-shadow: none;
  background: #fcfcfc;
  background: rgba(0, 0, 0, 0.5);
}

::selection {
  text-shadow: none;
  background: #fcfcfc;
  background: rgba(0, 0, 0, 0.5);
}
