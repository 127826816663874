@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);

.container_history {
  display: flex;
  margin-top: 15vh;
  align-items: center;
  justify-content: center;
}
.figure_history {
  font-family: var(--font-family);

  margin: 0 10vw;
  width: 80%;
  color: #ffffff;
  text-align: center;
  line-height: 1.4em;
  background: none;
  border-radius: 25px;
  padding-bottom: 5vh;
}

.figure_history * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.container_title {
  margin-bottom: 5vh;
}
.title_history {
  margin: 5vh 0;
}
.history_select {
  background-color: #0ba9ca;
  color: white;
  width: 150px;
  height: 30px;
  font-size: 18px;
  padding-left: 10px;
  border-radius: 5px;
}
.history_select option {
  background-color: #0ba9ca;
  padding-left: 10px;
  height: 30px;
  font-size: 18px;
}
.history_select.decorated option:hover {
  box-shadow: 0 0 10px 100px #1882a8 inset;
}

.history_select option:hover {
  background-color: #0ba9ca;
  padding-left: 10px;
  height: 30px;
  font-size: 18px;
}
.containertable {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;

  width: 20%;
  justify-content: space-around;
  width: fit-content;
  margin: 50px;
}
.imageContainerR {
  text-transform: uppercase;
  margin-left: -45px;
  font-weight: 500;
  text-align: center;
  position: relative;
  transition: 0.5s;
  width: fit-content;
  z-index: 9;
}
.imageContainerL {
  text-transform: uppercase;
  margin-right: -30px;
  font-weight: 500;
  text-align: center;
  position: relative;

  width: fit-content;
  z-index: 10;
}
.image_history {
  width: 150px;
  border-radius: 50%;
}
.eintragName {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.imageContainerR:hover {
  z-index: 11;
}
.imageContainerR:hover > img {
  opacity: 0.25;
  background-color: #141414;
  transition: 0.5s;
}
.imageContainerR:hover > div {
  opacity: 1;
  transition: 0.5s;
}
.imageContainerL:hover > img {
  opacity: 0.25;
  background-color: #141414;
  transition: 0.5s;
}
.imageContainerL:hover > div {
  opacity: 1;
  transition: 0.5s;
}

/* Thumb */
.arrowIcon {
  animation: left-to-right 2s ease-in infinite;
}
.symbolContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 11;
}
@keyframes left-to-right {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.Xicon {
  animation: sizing 2s ease-in infinite;
  position: absolute;
}

@keyframes sizing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
