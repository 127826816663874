.drapclout__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  padding: 2rem 6rem;
}

.drapclout__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.drapclout__navbar-links_logo {
  margin-right: 2rem;
}
.drapclout__navbar-links_logo img {
  height: auto;
  width: 130;
}
.drapclout__navbar-links_container {
  display: flex;
  flex-direction: row;
}
.drapclout__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.drapclout__navbar-links_container p,
.drapclout__navbar-sign p,
.drapclout__navbar-menu_container p {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;

  margin: 0 1rem;
  cursor: pointer;
}
.drapclout__navbar-sign button,
.drapclout__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #ff4820;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.drapclout__navbar-menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.drapclout__navbar-menu svg {
  cursor: pointer;
}

.drapclout__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  z-index: 500;
  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.drapclout__navbar-menu_container p {
  margin: 1rem 0;
}

.drapclout__navbar-menu_container-links-sign {
  display: none;
}
#logo {
  width: 400px;
}
@media screen and (max-width: 1250px) {
  #logo {
    width: 350px;
  }
  .drapclout__navbar-links_container {
    display: none;
  }

  .drapclout__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  #logo {
    width: 300px;
  }
  .drapclout__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .drapclout__navbar {
    padding: 2rem;
  }
  #logo {
    width: 200px;
  }
  .drapclout__navbar-sign {
    display: none;
  }

  .drapclout__navbar-menu_container {
    top: 20px;
    z-index: 200;
  }

  .drapclout__navbar-menu_container-links-sign {
    display: block;
  }
}

@media screen and (max-width: 300px) {
  .drapclout__navbar {
    padding: 2rem;
  }
  #logo {
    width: 150px;
  }
  .drapclout__navbar-sign {
    display: none;
  }

  .drapclout__navbar-menu_container {
    top: 20px;
  }

  .drapclout__navbar-menu_container-links-sign {
    display: block;
  }
}
