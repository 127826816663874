.container_ranking {
  display: flex;
  margin-top: 10vh;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

table.tidy_table tr:hover {
  box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 20px #0ba9ca, 0 0 30px #0ba9ca,
    0 0 40px #0ba9ca, 0 0 50px #0ba9ca;
  transition: 0.3s;
}
table.tidy_table td:nth-child(1) {
  border-right: 1px solid #eeeeee;
  color: #0ba9ca;
  font-weight: 900;
}

/** Score Table Color */
table.tidy_table td:nth-last-child(2) {
  border-right: none;
  letter-spacing: 0.5px;
  font-family: droid serif;
  color: #51c81b;
}

/** Background of Table */
table.tidy_table th,
table.tidy_table td {
  padding: 6px 20px 6px 20px;
  text-align: left;
}

table.tidy_table thead {
  background: none;
  color: white;
  border: 1px solid #474747;
  text-transform: uppercase;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 1px;
}
.table_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
table.tidy_table {
  border-spacing: 0;
  border-style: hidden;
  border-radius: 5px;
  border-collapse: collapse;
  border: 1px solid #474747;

  cursor: pointer;
  white-space: nowrap;

  width: 50%;
}
.Avatar {
  width: 5vw;
  border-radius: 50%;
}
/** Rank Column */
table.tidy_table th:nth-child(1),
table.tidy_table td:nth-child(1) {
  width: 10px;
  border-right: 1px solid #b8b8b8;
}

table.tidy_table th,
table.tidy_table td {
  padding: 6px 20px 6px 20px;
  border-right: 1px solid #b8b8b8;
}

table.tidy_table td:nth-last-child(1) {
  border-right: none;
  letter-spacing: 0.5px;
  font-family: droid serif;
}

td {
  color: white;
}

::-moz-selection {
  text-shadow: none;
  background: #fcfcfc;
  background: rgba(0, 0, 0, 0.5);
}

::selection {
  text-shadow: none;
  background: #fcfcfc;
  background: rgba(0, 0, 0, 0.5);
}

.container_filter_buttons {
  width: 80%;
  display: flex;
  justify-content: space-around;
  padding: 2vh;
  padding-bottom: 5vh;
}
.optionsButton {
  z-index: 100;
  overflow: hidden;
  color: #fff;
  background: none;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 10px;
  width: 120px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.5s;
  text-transform: uppercase;
  border: 1px solid #0ba9ca;
  text-align: center;
}
.optionsButton:hover {
  box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 20px #0ba9ca, 0 0 30px #0ba9ca,
    0 0 40px #0ba9ca, 0 0 50px #0ba9ca;
  background: #0ba9ca;
}
