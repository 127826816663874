.container_artist {
  width: 300px;

  z-index: 1;
}

.figure_content {
  width: 100%;
  height: fit-content;
  padding: 0% 5%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: space-between;
}

.figure_artist {
  position: relative;
  overflow: hidden;
  height: fit-content;
  width: 100%;
  color: #ffffff;
  text-align: left;
  display: flex;
  align-items: center;
  line-height: 1.4em;
  background-color: #141414;
  border-radius: 25px;
  padding-bottom: 5vh;
  box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 20px #0ba9ca, 0 0 30px #0ba9ca,
    0 0 40px #0ba9ca, 0 0 50px #0ba9ca;
  -webkit-animation: blink 2s infinite alternate;
  animation: blink 2s infinite alternate;
}

@keyframes blink {
  100% {
    box-shadow: 0 0 3px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #0ba9ca,
      0 0 70px #0ba9ca, 0 0 80px #0ba9ca;
  }
}
.figure_artist * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.figure_artist .background {
  width: 100%;
  vertical-align: text-top;
  opacity: 0.6;
  position: absolute;
  z-index: 2;
  -webkit-filter: grayscale(100%) blur(1.5px);
  filter: grayscale(100%) blur(1.5px);
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
  transform: scale(2);
  -ms-transform: scale(2); /* IE 9 */
  -moz-transform: scale(2); /* Firefox */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2);
}
/* Reset Button Start */
.resetcontainer {
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  padding-top: 3vh;
  padding-left: 1vw;
  width: 100%;
}
.outer {
  margin: auto;
  width: 35px;
  position: relative;
  cursor: pointer;
}
.inner {
  width: inherit;
  text-align: center;
}
label {
  font-size: 0.8em;
  line-height: 2em;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s ease-in;
  opacity: 0;
  cursor: pointer;
}
.inner:before,
.inner:after {
  position: absolute;
  content: '';
  height: 1px;
  width: inherit;
  background: #f39c12;
  left: 0;
  transition: all 0.3s ease-in;
}
.inner:before {
  top: 50%;
  transform: rotate(45deg);
}
.inner:after {
  bottom: 50%;
  transform: rotate(-45deg);
}
.outer:hover label {
  opacity: 1;
}
.outer:hover .inner:before,
.outer:hover .inner:after {
  transform: rotate(0);
}
.outer:hover .inner:before {
  top: 0;
}
.outer:hover .inner:after {
  bottom: 0;
}
/* Reset Button End */

/* imgcontainer Start  */

.imgcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile_pic {
  background-color: red;
  margin: 30px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 2px white;
  width: 65%;
}

/* imgcontainer  End */

/* artist_infobox Start  */
.container_artist h4 {
  margin: 0.5vh 0vw;
  font-size: 12px;
}

.artist_infobox {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Courier New', monospace;
}

.changes_cont {
  width: 100%;
}
.changes_cont span {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
}
/* artist_infobox End  */

/* ButtonGroup Start  */
.button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-top: 2vh;
}
.change_button {
  z-index: 100;

  color: #fff;
  background: none;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 10px;
  width: 50px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.5s;
  text-transform: uppercase;
  border: 1px solid #0ba9ca;
  text-align: center;
}
.change_button:hover {
  box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 20px #0ba9ca, 0 0 30px #0ba9ca,
    0 0 40px #0ba9ca, 0 0 50px #0ba9ca;
  background: #0ba9ca;
}
@media screen and (max-width: 1000px) {
  .profile_pic {
    margin: 10px;
  }
  .container_artist {
    width: 200px;
  }
  .profile_pic {
    width: 45%;
  }
  .changes_cont span {
    font-size: 10px;
  }
  .artist_infobox h4 {
    font-size: 10px;
  }
  .artist_infobox h2 {
    font-size: 14px;
  }
  .change_button {
    width: 40px;
    font-size: 10px;
    line-height: 20px;
  }
  .figure_artist {
    padding-bottom: 2vh;
  }
}
@media screen and (max-width: 500px) {
  .profile_pic {
    margin: 10px;
  }
  .container_artist {
    width: 150px;
  }
  .profile_pic {
    width: 35%;
  }
  .changes_cont span {
    font-size: 7px;
    margin: 0;
    line-height: 15px;
  }
  .artist_infobox h4 {
    font-size: 8px;
    margin: 0;
  }
  .artist_infobox h2 {
    font-size: 12px;
    margin: 0;
  }
  .change_button {
    width: 30px;
    font-size: 8px;
    line-height: 15px;
  }
  .figure_artist {
    padding-bottom: 0.5vh;
  }

  .resetcontainer {
    padding-top: 3vh;
  }
  .outer {
    width: 20px;
  }

  label {
    font-size: 6px;
    line-height: 12px;
  }
  .button-group {
    margin-top: 0.5vh;
  }
}
